.form {
	max-width: 500px;
	margin: 100px auto 0;
}

.logo {
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
}

.logo > img {
	width: 70px;
	height: 70px;
}

.logo > p {
	color: #fff;
	font-family: Titillium Web, sans-serif;
	font-size: 2.25rem;
	font-weight: 900;
	text-transform: uppercase;
}
