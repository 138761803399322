.logo {
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
}

.logo > img {
	width: 40px;
	height: 40px;
}

.logo > p {
	color: #fff;
	font-family: Titillium Web, sans-serif;
	font-size: 1.7rem;
	font-weight: 900;
	text-transform: uppercase;
}
